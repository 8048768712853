/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Switch, Route, NavLink} from 'react-router-dom';
import './index.css'

import { Contact, Experience, FAQ, Home, Projects, Socials } from './containers';
import React, { useState } from "react";
import { useEffect } from "react";

interface Properties {
    setPath: (route: string) => void;
    setError: (value: boolean) => void;
}

const NotFound = (props: Properties) => {
    useEffect(() => {
        props.setPath('/404');
        props.setError(true);

        return () => props.setError(false);
    }, [])

    return(
        <>
            <section>
                <p><span className='text-red-500'>ERROR:</span> 404 Page Not Found.</p>
                <p><span className='text-red-500'>MESSAGE:</span> It appears the page you are looking for either does not exist, or is under construction.</p>
            </section>
        </>
    )
}

const Routes = (props: Properties) => {
    return(
        <Switch>
            <Route exact path="/"><Home path={props.setPath} /></Route>
            <Route path="/faq"><FAQ path={props.setPath} /></Route>
            <Route path="/experience"><Experience path={props.setPath} /></Route>
            <Route path="/projects"><Projects path={props.setPath} /></Route>
            <Route path="/socials"><Socials path={props.setPath} /></Route>
            {/* <Route path="/contact"><Contact path={props.setPath} /></Route> */}
            <Route><NotFound setPath={props.setPath} setError={props.setError} /></Route>
        </Switch>
    )
}

const Landing = () => {
    const [path, setPath] = useState('');
    const [error, setError] = useState(false);
    const [menu, setMenu] = useState(false);
    const [description, setDescription] = useState('');
    const [descNo, setDescNo] = useState(0);

    const descriptions = ['Developing 💻', 'Designing 💡', 'Photographing 📸', 'Walking 🚶‍♂️'];

    useEffect(() => {
        setDescription(descriptions[descNo]);

        const ticker = setTimeout(() => {
            if((descNo + 1) > descriptions.length - 1) {
                setDescNo(0);
            } else {
                setDescNo(descNo + 1)
            }
        }, 3000);

        return (() => clearTimeout(ticker))
    }, [descNo])

    const MenuToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setMenu(!menu);
    }

    return(
        <div className=' overflow-x-hidden relative flex flex-col items-center fill-available bg-gradient-to-br from-gray-900 to-gray-800 animate-gradient-xy'>
            {/* Mobile menu to display after the burger is pressed. */}
            <div className={`${menu ? ' translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out transform sm:hidden h-screen w-screen bg-gray-900 absolute z-40 flex flex-col gap-6 items-center justify-center text-green-500 text-3xl`}>
                <NavLink onClick={() => {setMenu(false)}} exact activeClassName='animate-pulse' to='/' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>Home</NavLink>
                <NavLink onClick={() => {setMenu(false)}} activeClassName='animate-pulse' to='/faq' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>FAQ</NavLink>
                <NavLink onClick={() => {setMenu(false)}} activeClassName='animate-pulse' to='/experience' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>Experience</NavLink>
                {/* <NavLink onClick={() => {setMenu(false)}} activeClassName='animate-pulse' to='/contact' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>Contact</NavLink> */}
                <NavLink onClick={() => {setMenu(false)}} activeClassName='animate-pulse' to='/projects' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>Projects</NavLink>
                <NavLink onClick={() => {setMenu(false)}} activeClassName='animate-pulse' to='/socials' className='md:hover:text-green-400 w-full flex justify-center items-center h-16 text-center md:hover:underline transition-all'>Socials</NavLink>
            </div>

            {/* Desktop navigation with burger switchout. */}
            <nav className='overflow-hidden w-full flex-none h-16 flex flex-row items-center justify-between text-gray-100 px-6 sm:12'>
                <h1 className='text-sm sm:text-lg font-mono'>&lt;Emilis state='<span className='text-green-500'>{description}</span>' /&gt;</h1>
                <div className='hidden md:flex flex-row justify-center items-center gap-8 text-green-500 text-lg font-mono'>
                    <NavLink exact activeClassName='animate-hop' to='/' className='hover:text-green-400 transition-all'>Home</NavLink>
                    <NavLink activeClassName='animate-hop' to='/faq' className='hover:text-green-400 transition-all'>FAQ</NavLink>
                    <NavLink activeClassName='animate-hop' to='/experience' className='hover:text-green-400 transition-all'>Experience</NavLink>
                    {/* <NavLink activeClassName='animate-hop' to='/contact' className='hover:text-green-400 transition-all'>Contact</NavLink> */}
                    <NavLink activeClassName='animate-hop' to='/projects' className='hover:text-green-400 transition-all'>Projects</NavLink>
                    <NavLink activeClassName='animate-hop' to='/socials' className='hover:text-green-400 transition-all'>Socials</NavLink>
                </div>
                <div className='flex md:hidden flex-row justify-center items-center gap-10 z-50 text-lg font-mono'>
                    <svg
                        className="w-9 h-9 p-1 text-green-500 hover:text-green-400 transition-all rounded-full"
                        x-show="!showMenu"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={MenuToggle}
                    >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </div>
            </nav>

            <div className="w-full flex-1 flex overflow-hidden flex-col p-6 lg:p-0 items-center justify-center">
                <div className="h-full w-full lg:w-2/3 lg:max-w-2/3 flex flex-col md:h-2/3 shadow-xl">
                    <header className={`h-8 ${error ? 'bg-gradient-to-r from-red-500 to-red-400' : 'bg-indigo-500'} w-full flex flex-row justify-between items-center px-5 rounded-tr-md rounded-tl-md`}>
                        <h1 className="text-md font-mono text-indigo-100 shadow-sm">CMD: {path}</h1>
                        <div className='flex flex-row gap-2'>
                            <div className="h-4 w-4 rounded-full bg-green-500 shadow-md"></div>
                            <div className="h-4 w-4 rounded-full bg-yellow-500 shadow-md"></div>
                            <div className="h-4 w-4 rounded-full bg-red-500 shadow-md"></div>
                        </div>
                    </header>
                    <div className='bg-gray-800 flex-1 md:h-96 w-full flex flex-col gap-5 px-3 py-2 text-gray-100 rounded-br-md rounded-bl-md overflow-y-auto'>
                        <section>
                            <p><span className='text-yellow-500'>v1.0.0</span></p>
                        </section>
                        <Routes setPath={setPath} setError={setError} />
                        <section>
                            <p><span className='text-green-400'>&gt;</span> <span className='animate-pulse'>|</span></p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

ReactDOM.render(<Router><Landing /></Router>, document.getElementById('root'));