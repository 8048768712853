/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react";
import { useEffect } from "react";

interface FAQProperties {
    path: (route: string) => void;
}

const FAQ = (props: FAQProperties) => {
    const [age, setAge] = useState(0);

    useEffect(() => {
        props.path('/faq');

        setAge(Age())
    }, [])

    const Age = (): number => {
        return Math.abs(new Date(Date.now() - new Date(1999, 6, 10).getTime()).getUTCFullYear() - 1970)
    }

    return (
        <>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type name_origin.txt</span></p>
                <p>
                    Lithuania
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type age.txt</span></p>
                <p>
                    I am {age}. Started programming mod menus for Call of Duty at the age of 10, fell in love with making software do things I wanted it to do and how it worked behind the scenes.
                    From there I built my knowledge by exploring languages and methodologies all the way to my first job which I ended up dropping out of University for.
                    The rest is history.
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type preferred_stack.txt</span></p>
                <p>
                    My day to day job usually involves C++ or C# whilst working on desktop applications.
                    At home, I tend to work on personal, web-based, projects leveraging React (TypeScript!) for the frontend and Express for the backend.
                </p>
            </section>
        </>
    )
}

export default FAQ;