/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useState } from "react";

interface ProjectsProperties {
    path: (route: string) => void;
}

interface ProjectModel {
    id: number;
    codename: string;
    description: string;
    link: string;
}

const ProjectInfo: ProjectModel[] = [
	{
		id: 0,
		codename: 'Formula Apex',
		description: 'Formula Apex is an F1 data aggregating dashboard with the aim to make Formula 1 championship and race data visually pleasing and easy to consume. This is an ongoing project by myself and a lot of updates are posted over on the official Twitter account @FormulaApexF1.',
		link: 'https://formulaapex.co.uk'
	},
	{
		id: 1,
		codename: 'Reactor',
		description: 'Reactor is an NPM package used for bootstrapping Tailwind and Typescript enabled React projects. It was born from my setup woes when it came to using Tailwind with React and the additional setup steps it required.',
		link: 'https://www.npmjs.com/package/@tobulevicius/reactor'
	},
    {
        id: 2,
        codename: 'Spot',
        description: 'Spot is a playlist suggesting application that looks into your Spotify listening history. Using your historical data, it cultivates a brand new playlist for you with music that you have not heard before but are likely to enjoy.',
        link: 'https://spot.emilis.co.uk'
    },
    {
        id: 3,
        codename: 'Todo',
        description: 'The famous todo app. Everyone has one and it is a right of passage that must be undertaken by every developer at least once. Therefore, I have created one myself. There\'s not a whole lot to it currently but I will expand it eventually. Note: This was developed mostly for desktop, I plan on switching the Sass out for Tailwind and will at that point make it fully responsive.',
        link: 'https://todo.emilis.co.uk'
    }
];

const Projects = (props: ProjectsProperties) => {
    let presses = 0;
    const [current, setCurrent] = useState<ProjectModel>(ProjectInfo[0]);

    const KeyDown = (e: KeyboardEvent | React.MouseEvent) => {
        // Key event to ensure it only reacts to the spacebar.
        if(e instanceof KeyboardEvent) {
            if (e.code === 'Space') {
                if((presses + 1) > (ProjectInfo.length - 1)) presses = 0;
                else presses += 1;
    
                setCurrent(ProjectInfo[presses])
            }
        } else {
            // Mouse click on the button, so just fire the update.
            if((presses + 1) > (ProjectInfo.length - 1)) presses = 0;
            else presses += 1;

            setCurrent(ProjectInfo[presses])
        }
    }

    useEffect(() => {
        props.path('/projects');

        window.addEventListener("keydown", KeyDown);
        return () => window.removeEventListener("keydown", KeyDown);
    }, [])

    return(
        <>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>more projects.txt</span></p>
                <p><span className=' text-green-500'>Codename: </span>{current.codename}</p>
                <p><span className='text-green-500'>Description: </span> {current.description}</p>
                <p><span className='text-green-500'>Link: </span><a href={current.link} rel='noreferrer' target='_blank' className='text-indigo-500 hover:text-indigo-400 transition-all'>{current.link}</a></p>
            </section>
                <p className='bold'>Page: {current.id + 1}/{ProjectInfo.length}</p>
                <code>
                    Press 
                    <kbd className='hidden lg:inline-block rounded-md border border-solid border-white py-1 px-2 mx-2'>Space</kbd>
                    <kbd className='lg:hidden rounded-md border border-solid border-white py-1 px-2 mx-2' onClick={KeyDown}>Here</kbd>
                    to display the next page.
                </code>
            <section>

            </section>
        </>
    )
}

export default Projects;