/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

interface ExperienceProperties {
    path: (route: string) => void;
}

const Experience = (props: ExperienceProperties) => {
    useEffect(() => {
        props.path('/experience');
    }, [])

    return(
        <>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type aviation.txt</span></p>
                <p>
                    Creating and maintaining software for the realtime tracking and 3D visualisation of planes providing critical situational awareness.
                    These systems were used to ensure adherence to aviation rules and regulations by the pilots during airshows.
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type government.txt</span></p>
                <p>
                    Development experience for various governments, creating and maintaining secure content management systems ensuring a high standard of architecture and execution.
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type defence.txt</span></p>
                <p>
                    Creating and maintaining highly robust and secure systems to aid with the gathering and visualisation of data in a magnitude of formats from tables and charts up to 3D precise location data.
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type scientific-instrumentation.txt</span></p>
                <p>
                    Creating and maintaining software that interfaces with intricate hardware for particle identification and characterisation.
                </p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type transportation.txt</span></p>
                <p>
                    Experienced in the Transporation sector, creating and maintaining software to allow for the visualisation of the build projections of a large scale railway project along with other projects including flood level monitoring for transporation safety purposes.
                </p>
            </section>
        </>
    )
}

export default Experience;