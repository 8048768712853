/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

interface SocialProperties {
    path: (route: string) => void;
}

const Socials = (props: SocialProperties) => {

    useEffect(() => {
        props.path('/socials');
    }, [])

    return (
        <>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type twitter.txt</span></p>
                <p>Follow me on Twitter: <a href='https://twitter.com/tobulevicius' className='text-indigo-500 hover:underline'>@tobulevicius</a></p>
            </section>

			<section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type linkedin.txt</span></p>
                <p>Connect with me on LinkedIn: <a href='https://www.linkedin.com/in/emilis-tobulevicius-b801ab152/' className='text-indigo-500 hover:underline'>Emilis Tobulevicius</a></p>
            </section>

			<section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type npm.txt</span></p>
                <p>Use my NPM packages if you want!: <a href='https://www.npmjs.com/~tobulevicius' className='text-indigo-500 hover:underline'>@tobulevicius</a></p>
            </section>

			<section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type github.txt</span></p>
                <p>It's not my first choice for repositories but I sometimes use Github, check it out here: <a href='https://github.com/tobulevicius' className='text-indigo-500 hover:underline'>@tobulevicius</a></p>
            </section>
        </>
    )
}

export default Socials;