/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";

interface HomeProperties {
    path: (route: string) => void;
}

const Home = (props: HomeProperties) => {
    useEffect(() => {
        props.path('/home');
    }, [])

    return(
        <>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type name.txt</span></p>
                <p>Emilis Tobulevicius</p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type occupation.txt</span></p>
                <p>Software Engineer</p>
            </section>
            <section>
                <p className='font-bold'><span className='text-green-400'>&gt;</span> <span className='text-pink-500'>type bio.txt</span></p>
                <p>
                    Based in the UK on the borders of the Forest of Dean. Experience spanning over a multitude of industries built up over 10 years of personal and professional development.
                </p>
            </section>
        </>
    )
}

export default Home;